import axios from 'axios'
import { authService } from '../pages/login/routes/Login'
import { loggedoutReasons } from '../constants/statusMessages'
import SanityHelper from 'sanity-query-helper'
import sanityOptions from './sanity'
import { useUserContext } from '../context/UserProvider'
import { matchPath, useLocation } from 'react-router-dom'
import JSZip from 'jszip'
import FileSaver from 'file-saver'
import getLastDateInMonth from '../utils/getLastDateInMonth'

const qs = require('qs')

const db = localStorage.getItem('db')

const shouldNavigateToLogout = (errorResponse) => {
  if (![401, 403].includes(errorResponse?.response?.status)) {
    return false
  }

  return errorResponse?.request?.responseURL.includes('/api/flows')
}

export const axiosInstance = axios.create({
  timeout: 300000,
  baseURL: '/api',
  headers: {
    'cache-control': 'no-cache',
  },
})

axiosInstance.interceptors.response.use(
  ({ data }) => data,
  (error) => {
    if (shouldNavigateToLogout(error)) {
      sessionStorage.setItem('loggedoutReason', loggedoutReasons.NORMAL_LOGOUT)
      window.location.replace('/logout')
    }
    return Promise.reject(error)
  },
)

axiosInstance.interceptors.request.use(async (config) => {
  const user = await authService.getUser()
  config.headers.authorization = `Bearer ${user.access_token}`
  return config
})

export const fetchCars = async (companyId, consolidateInvoiceCode) => {
  const query = {}
  if (consolidateInvoiceCode !== '_ALL_') {
    query.consolidatedInvoice = consolidateInvoiceCode
  }

  return axiosInstance
    .get(`/fleet${qs.stringify(query, { addQueryPrefix: true })}`, {
      params: {
        companyId,
      },
    })
    .catch(console.error)
}

export const fetchCarsSimple = async (companyId, consolidateInvoiceCode) => {
  const query = {}
  if (consolidateInvoiceCode !== '_ALL_') {
    query.consolidatedInvoice = consolidateInvoiceCode
  }

  return axiosInstance
    .get(`/fleet/simple${qs.stringify(query, { addQueryPrefix: true })}`, {
      params: {
        companyId,
      },
    })
    .catch(console.error)
}

export const fetchCar = async (companyId, accountNo) => {
  return axiosInstance
    .get(`/fleet`, {
      params: {
        companyId,
        registrationNumber: accountNo,
      },
    })

    .catch(console.error)
}

export const fetchCarByAccountNo = async (companyId, accountNo) => {
  return axiosInstance
    .get(`/fleet/account/${accountNo}`, {
      params: {
        companyId,
      },
    })

    .catch(console.error)
}

export const fetchCarByBudgetNo = async (companyId, budgetNo) => {
  return axiosInstance
    .get(`/fleet/budget/${budgetNo}`, {
      params: {
        companyId,
      },
    })

    .catch(console.error)
}

export const fetchFleetReport = async (companyId, term, type) => {
  let url = '/report/fleet/' + companyId + '/' + term
  return axiosInstance
    .get(url, {
      params: {
        leasing: type === 'leasing',
        ...(db && { db }),
      },
    })

    .catch(console.error)
}

export const fetchFleetReportTerms = async (companyId) => {
  return axiosInstance
    .get('/report/fleet/dates/' + companyId, {
      params: {
        ...(db && { db }),
      },
    })

    .catch(console.error)
}

export const fetchUser = async () => {
  return axiosInstance
    .get('/user')

    .catch((error) => {
      //redirect to login if GET /user is forbidden
      if (
        error.response &&
        error.response.status === 403 &&
        window.location.pathname !== '/logout'
      ) {
        sessionStorage.setItem('loggedoutReason', loggedoutReasons.UNKNOWN_USER)
        window.location.replace('/logout')
      }
    })
}

export const fetchUserWithDetails = async () => {
  const userdata = await fetchUser()
  if (!userdata) return
  const contactDetails = await fetchContactDetails(userdata.user.id)
  userdata.user.contactDetails = contactDetails
  return userdata
}

export const fetchUsages = async (customerId) => {
  return axiosInstance
    .get('/usage', { params: { customerId } })

    .catch(console.error)
}

export const fetchDeviations = async (companyId) => {
  return axiosInstance
    .get('/usage/deviations/' + companyId)

    .catch(console.error)
}

export const fetchInvoices = async (companyId) => {
  return axiosInstance
    .get('/invoice', { params: { companyId } })

    .catch(console.error)
}

export const fetchInvoiceDetails = async (invoiceNo) => {
  return axiosInstance
    .get('/report/invoice/details/' + invoiceNo, {
      params: {
        ...(db && { db }),
      },
    })

    .catch(console.error)
}

export const fetchInvoiceDocument = async (encryptedDocId, invoiceNumber) => {
  postToDownload(
    `/invoicedocument/${encodeURIComponent(encryptedDocId)}`,
    `${invoiceNumber}.pdf`,
    {
      method: 'GET',
      responseType: 'arraybuffer',
    },
  )
}

export const fetchInvoiceDocumentAttachments = async (docId, invoiceNumber) => {
  const data = await axiosInstance.get(
    `/invoicedocument?encryptedDocId=${encodeURIComponent(docId)}`,
  )
  const id = Object.keys(data || {})?.[0]

  if ((data[id] || []).length > 0) {
    if (data[id].length > 1) {
      const zip = new JSZip()

      for (const attachment of data[id]) {
        const attachmentFile = await axiosInstance.get(
          `/invoicedocument?encryptedDocId=${encodeURIComponent(
            docId,
          )}&attachmentId=${attachment.Id}`,
          {
            responseType: 'arraybuffer',
          },
        )
        zip.file(attachment.FileName, attachmentFile)
      }
      zip.generateAsync({ type: 'blob' }).then((content) => {
        FileSaver.saveAs(content, invoiceNumber + '.zip')
      })
    } else {
      const singleAttachment = data[id][0]
      const indexOfExtension = singleAttachment.FileName.indexOf('.')
      const fileType = singleAttachment.FileName.slice(indexOfExtension)
      postToDownload(
        `/invoicedocument?encryptedDocId=${encodeURIComponent(
          docId,
        )}&attachmentId=${singleAttachment.Id}`,
        `${invoiceNumber}${fileType}`,
        {
          method: 'GET',
          responseType: 'arraybuffer',
        },
      )
    }
  }
}

export const fetchInvoiceReport = async (companyId) => {
  return axiosInstance
    .get('/report/invoice/' + companyId, {
      params: {
        ...(db && { db }),
      },
    })

    .catch(console.error)
}

export const fetchEnvironmentReport = async (companyId) => {
  return axiosInstance
    .get('/report/environment/' + companyId, {
      params: {
        ...(db && { db }),
      },
    })

    .catch(console.error)
}

export const fetchDrivers = async (companyId) => {
  return axiosInstance
    .get('/drivers', { params: { companyId } })

    .catch(console.error)
}

export const quitDriver = async (driverId) => {
  return axiosInstance
    .post('/drivers/quit/' + driverId)

    .catch(console.error)
}

export const fetchContacts = async (companyId) => {
  return axiosInstance
    .get(`/contacts/from/company/${companyId}`)

    .catch(console.error)
}

export const fetchFuelReport = async (companyId) => {
  return axiosInstance
    .get('/report/fuel/dates/' + companyId, {
      params: {
        ...(db && { db }),
      },
    })

    .catch(console.error)
}

export const fetchFuelReportDetails = async (companyId, year, month) => {
  return axiosInstance
    .get('/report/fuel/account/' + companyId + '/' + year + '/' + month, {
      params: {
        ...(db && { db }),
      },
    })

    .catch(console.error)
}

export const fetchMileageReport = async (
  companyId,
  fromDate,
  toDate,
  accountNo,
) => {
  const accountQuery = accountNo === undefined ? '' : '?account=' + accountNo
  return axiosInstance
    .get(
      '/report/mileage/' +
        companyId +
        '/' +
        fromDate +
        '/' +
        toDate +
        accountQuery,
      {
        params: {
          ...(db && { db }),
        },
      },
    )
    .catch(console.error)
}

export const fetchPurchaseReport = async (companyId) => {
  return axiosInstance
    .get('/report/transaction/dates/' + companyId, {
      params: {
        ...(db && { db }),
      },
    })

    .catch(console.error)
}

export const fetchPurhcaseReportDetails = async (companyId, year, month) => {
  return axiosInstance
    .get(
      '/report/transaction/account/' + companyId + '/' + year + '/' + month,
      {
        params: {
          ...(db && { db }),
        },
      },
    )

    .catch(console.error)
}

export const fetchTollReport = async (companyId) => {
  return axiosInstance
    .get('/report/toll/dates/' + companyId, {
      params: {
        ...(db && { db }),
      },
    })

    .catch(console.error)
}

export const fetchTollReportDetails = async (companyId, year, month) => {
  return axiosInstance
    .get('/report/toll/account/' + companyId + '/' + year + '/' + month, {
      params: {
        ...(db && { db }),
      },
    })

    .catch(console.error)
}

export const fetchFleetReportDetails = async (companyId, accountId, term) => {
  return axiosInstance
    .get(`/report/fleet/details/${companyId}/${term}/${accountId}`, {
      params: {
        ...(db && { db }),
      },
    })

    .catch(console.error)
}

export const fetchTollTransactionDetails = async ({ year, month, account }) => {
  return axiosInstance
    .get(`/report/toll/transaction/${account}/${year}/${month}`, {
      params: {
        ...(db && { db }),
      },
    })

    .catch(console.error)
}

export const fetchPurhcaseExpenseReport = async ({ year, month, account }) => {
  return axiosInstance
    .get(`/report/transaction/expense/${account}/${year}/${month}`, {
      params: {
        ...(db && { db }),
      },
    })

    .catch(console.error)
}

export const fetchFuelExpenseReport = async ({ year, month, account }) => {
  return axiosInstance
    .get(`/report/fuel/expense/${account}/${year}/${month}`, {
      params: {
        ...(db && { db }),
      },
    })

    .catch(console.error)
}

export const fetchFleetReportExpense = ({
  companyId,
  type,
  account,
  term,
  itemNo,
}) => {
  return axiosInstance
    .get(
      `/report/fleet/expense/${companyId}/${term}/${account}/${itemNo}/${type}`,
      {
        params: {
          ...(db && { db }),
        },
      },
    )

    .catch(console.error)
}

export const exportFleet = ({ companyId, from, to }) =>
  postToDownload(
    `/export/report/fleet/${companyId}/${from}/${to}`,
    `fleet-${from}-${to}.xlsx`,
  )

export const postToDownload = async (
  url,
  fileName,
  options = {
    method: 'POST',
    responseType: 'blob',
    params: {
      ...(db && { db }),
    },
  },
) => {
  return axiosInstance({
    url,
    ...options,
  })
    .then(async (response) => {
      const data = await response
      const dataBlob = new Blob([data])
      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(dataBlob, fileName)
      } else {
        const url = window.URL.createObjectURL(dataBlob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      }
    })
    .catch(console.error)
}

export const createDriverWithDetails = async (companyId, driverDetails) => {
  const { name, address, postCode, phone, mail } = driverDetails
  return axiosInstance.post('/contacts/person', {
    CompanyExternalID: companyId,
    Name: name,
    Address: address,
    PostCode: postCode,
    Phone: phone,
    Mail: mail,
  })
}

export const updateDriverDetails = async (
  companyId,
  driverId,
  driverDetails,
) => {
  const {
    name,
    address,
    postCode,
    phone,
    mail,
    driver,
    manager,
    carConfig,
    jobTitle,
  } = driverDetails

  const data = {
    PersonContactID: driverId,
    CompanyExternalID: companyId,
    Name: name,
    Address: address,
    PostCode: postCode,
    Phone: phone,
    Mail: mail,
    isDriver: driver,
    isManager: manager,
    carConfigAccess: carConfig,
    jobTitle: jobTitle,
  }

  return axiosInstance.put('/contacts/person', data)
}

export const patchToggleCarReplacement = async (accountNo) => {
  let url = `/fleet/car/` + accountNo + `/toggleReplacment`
  return axiosInstance.patch(url).catch(console.error)
}

export const fetchContactDetails = async (contactId) => {
  return axiosInstance
    .get(`/contacts/person/${contactId}`)

    .catch(console.error)
}

export const fetchSalesPerson = async (companyId) => {
  return axiosInstance
    .get(`/contacts/salesperson/${companyId}`)

    .catch(console.error)
}

export const recordMileage = async (accountNumber, mileage) => {
  return axiosInstance
    .post('/mileage', { AccNumber: '' + accountNumber, Mileage: '' + mileage })
    .catch((err) => {
      throw err.response.data.Message || err.message
    })
}

export const changeDriver = async (accountNo, newDriverUserId) => {
  return axiosInstance
    .put(`/drivers/set/${accountNo}/${newDriverUserId}`)

    .catch((err) => {
      throw err.response.data.Message || err.message
    })
}

export const faqFromSanity = async () => {
  return await new SanityHelper({ sanityOptions })
    .ofType('informationBlock')
    .send()
}

export const contactFromSanity = async () => {
  return await new SanityHelper({ sanityOptions }).ofType('contact').send()
}

export const newsFromSanity = async () => {
  return await new SanityHelper({ sanityOptions }).ofType('news').send()
}

export const categoriesFromSanity = async () => {
  return await new SanityHelper({ sanityOptions }).ofType('categories').send()
}

export const serviceCardFromSanity = async () => {
  return await new SanityHelper({ sanityOptions }).ofType('serviceCard').send()
}

export const createAndSetNewDriver = async (companyId, accountNo, details) => {
  details.phone = details.phone.replace(/\s/g, '')
  return createDriverWithDetails(companyId, details).then((id) =>
    changeDriver(accountNo, id),
  )
}

export const getPrice = async (companyId, payload) =>
  axiosInstance.post(`/car-config/calculate/monthly/${companyId}`, payload)

export const getWheelPackages = async ({
  make,
  model,
  engine,
  fuelType,
  hybridCode,
  companyId,
  vehicleType,
}) => {
  const query = {
    make,
    model,
    engine,
    fuelType,
    hybridCode,
    vehicleType,
    companyId,
  }
  return axiosInstance.get(
    `/wheel-packages${qs.stringify(query, { addQueryPrefix: true })}`,
  )
}

export const getInsuranceInformation = async (accountNo) => {
  return axiosInstance.get(`/fleet/details/${accountNo}/insurance`)
}

export const getAgreements = async ({
  make,
  model,
  engine,
  fuelType,
  hybridCode,
  carCategory,
  drivingDistance,
  termCountYear,
}) => {
  const query = {
    make,
    model,
    engine,
    fuelType,
    hybridCode,
    carCategory,
    drivingDistance,
    termCountYear,
  }
  return axiosInstance.get(
    `/agreements/service${qs.stringify(query, { addQueryPrefix: true })}`,
  )
}

export const useReportDownloader = () => {
  let location = useLocation()
  const {
    chosenCompany,
    consolidateInvoice,
    fleetReportTerm,
    environmentReportYear,
  } = useUserContext()
  if (!chosenCompany || !consolidateInvoice) {
    return
  }

  const path = location.pathname
  const companyId = chosenCompany.id
  const ciaCode =
    consolidateInvoice.ConsolidateCode !== '_ALL_'
      ? consolidateInvoice.ConsolidateCode
      : ''

  const match = (pattern) => {
    const match = matchPath(
      {
        path: pattern,
        caseSensitive: true,
        end: true,
      },
      path,
    )
    return match || null
  }

  const urlMaps = [
    {
      path: '/reports/reports-overview',
      exportUrl: (match) => `/export/report/reports-overview/${companyId}/`,
    },
    {
      path: '/reports/fleet',
      exportUrl: (match) =>
        `/export/report/fleet/${companyId}/${fleetReportTerm}${
          ciaCode ? `?CIA=${ciaCode}` : ''
        }`,
      filename: `Fleetreport-${companyId}${
        ciaCode ? `-${ciaCode}` : ''
      }-${fleetReportTerm}.xlsx`,
    },
    {
      path: '/reports/leasing',
      exportUrl: (match) =>
        `/export/report/fleet/${companyId}/${fleetReportTerm}?leasing=true${
          ciaCode ? `&CIA=${ciaCode}` : ''
        }`,
      filename: `Leasingreport-${companyId}${
        ciaCode ? `-${ciaCode}` : ''
      }-${fleetReportTerm}.xlsx`,
    },
    {
      path: '/reports/fleet/account/:accountNo',
      exportUrl: (match) =>
        `/export/report/fleet/details/${companyId}/${fleetReportTerm}/${match.params.accountNo}`,
      filename: (match) =>
        `Fleetreport-details-${companyId}-${match.params.accountNo}-${fleetReportTerm}.xlsx`,
    },

    {
      path: '/reports/invoice/:invoiceNo',
      exportUrl: (match) =>
        `/export/report/invoice/details/${match.params.invoiceNo}`,
      filename: (match) => `Invoicereport-${match.params.invoiceNo}.xlsx`,
    },
    {
      path: '/reports/mileage',
      exportUrl: (match) =>
        `/report/export/excel/mileage/${companyId}/${fleetReportTerm}/${getLastDateInMonth(
          fleetReportTerm,
        )}
        ${ciaCode ? `/?CIA=${ciaCode}` : ''}`,
      filename: `MileageReport-${companyId}${
        ciaCode ? `-${ciaCode}` : ''
      }-${fleetReportTerm}-/${getLastDateInMonth(fleetReportTerm)}.xlsx`,
      method: 'GET',
    },
    {
      path: '/reports/environment',
      exportUrl: `/export/report/environment/${companyId}/${environmentReportYear}${
        ciaCode ? `?CIA=${ciaCode}` : ''
      }`,
      filename: `Environmentreport-${companyId}${
        ciaCode ? `-${ciaCode}` : ''
      }-${environmentReportYear}.xlsx`,
    },
    {
      path: '/reports/fuel',
      exportUrl: `/export/report/fuel/dates/${companyId}${
        ciaCode ? `?CIA=${ciaCode}` : ''
      }`,
      filename: `Fuelreport-${companyId}${ciaCode ? `-${ciaCode}` : ''}.xlsx`,
    },
    {
      path: '/reports/fuel/:year/:month',
      exportUrl: (match) =>
        `/export/report/fuel/account/${companyId}/${match.params.year}/${match.params.month}`,
      filename: (match) =>
        `Fuelreport-details-${companyId}-${match.params.year}-${match.params.month}.xlsx`,
    },
    {
      path: '/reports/fuel/expense/:accountId/:year/:month',
      exportUrl: (match) =>
        `/export/report/fuel/expense/${match.params.accountId}/${
          match.params.year
        }/${match.params.month}${ciaCode ? `?CIA=${ciaCode}` : ''}`,
      filename: (match) =>
        `Fuelreport-expense-${match.params.accountId}${
          ciaCode ? `-${ciaCode}` : ''
        }-${match.params.year}-${match.params.month}.xlsx`,
    },
    {
      path: '/reports/purchase',
      exportUrl: `/export/report/transaction/dates/${companyId}${
        ciaCode ? `?CIA=${ciaCode}` : ''
      }`,
      filename: `Purchasereport-${companyId}${
        ciaCode ? `-${ciaCode}` : ''
      }.xlsx`,
    },
    {
      path: '/reports/purchase/:year/:month',
      exportUrl: (match) =>
        `/export/report/transaction/account/${companyId}/${match.params.year}/${match.params.month}`,
      filename: (match) =>
        `Purchasereport-transaction-${companyId}-${match.params.year}-${match.params.month}.xlsx`,
    },
    {
      path: '/reports/purchase/expense/:accountId/:year/:month',
      exportUrl: (match) =>
        `/export/report/transaction/expense/${match.params.accountId}/${match.params.year}/${match.params.month}`,
      filename: (match) =>
        `Purchasereport-expense-${match.params.accountId}-${match.params.year}-${match.params.month}.xlsx`,
    },
    {
      path: '/reports/toll',
      exportUrl: `/export/report/toll/dates/${companyId}${
        ciaCode ? `?CIA=${ciaCode}` : ''
      }`,
      filename: `Tollreport-${companyId}${ciaCode ? `-${ciaCode}` : ''}.xlsx`,
    },
    {
      path: '/reports/toll/:year/:month',
      exportUrl: (match) =>
        `/export/report/toll/account/${companyId}/${match.params.year}/${match.params.month}`,
      filename: (match) =>
        `Tollreport-account-${companyId}-${match.params.year}-${match.params.month}.xlsx`,
    },
    {
      path: '/reports/toll/transaction/:accountId/:year/:month',
      exportUrl: (match) =>
        `/export/report/toll/transaction/${match.params.accountId}/${
          match.params.year
        }/${match.params.month}${ciaCode ? `?CIA=${ciaCode}` : ''}`,
      filename: (match) =>
        `Tollreport-transaction-${match.params.accountId}${
          ciaCode ? `-${ciaCode}` : ''
        }-${match.params.year}-${match.params.month}.xlsx`,
    },
  ]

  const downloadDetails = urlMaps.find((obj) => match(obj.path))

  return downloadDetails
    ? () => {
        const getUrl =
          typeof downloadDetails.exportUrl === 'function'
            ? downloadDetails.exportUrl
            : () => downloadDetails.exportUrl
        const getFilename =
          typeof downloadDetails.filename === 'function'
            ? downloadDetails.filename
            : () => downloadDetails.filename
        const getMethod =
          downloadDetails.method === 'function'
            ? downloadDetails.method
            : () => downloadDetails.method

        const url = getUrl(match(downloadDetails.path))
        const filename = getFilename(match(downloadDetails.path))
        const method = getMethod(match(downloadDetails.path))

        postToDownload(url, filename, method)
      }
    : null
}

export const getPendingSignings = async (contactId) =>
  axiosInstance.get(`/signings?contactId=${contactId}`)

export const getPendingBudgets = async (contactId) =>
  axiosInstance.get(`/budgets?contactId=${contactId}`)

export const getPendingBatchSignings = async (contactId) =>
  axiosInstance.get(`/batchSignings?contactId=${contactId}`)

export const getFlow = async (flowId) => axiosInstance.get(`/flow/${flowId}`)

export const deleteFlow = async (flowId) =>
  axiosInstance.delete(`/flow/${flowId}`)

export const startBatchSigningProcess = async (payload) =>
  axiosInstance.post('/flows/signing-batch', { ...payload })

export const getBudgetSelections = async (contactId) =>
  axiosInstance.get(`/budgetSelections?contactId=${contactId}`)

export const getPendingUserTasks = async (flowId) =>
  axiosInstance.get(`/flows/signing/${flowId}/tasks`)

export const getSigningFlows = async (referenceId) =>
  axiosInstance.get(`/flows/signing/${referenceId}`)

export const getSigningTasks = async () =>
  axiosInstance.get('/tasks/sign-budget-acceptance')

export const downloadAttachment = async (flowId, attachmentKey, mimeType) => {
  try {
    const buffer = await axiosInstance.get(
      `/attachments/download?flowId=${flowId}&attachmentKey=${attachmentKey}`,
      {
        responseType: 'arraybuffer',
      },
    )
    const file = new Blob([buffer], { type: mimeType })
    return URL.createObjectURL(file)
  } catch (e) {
    throw new Error(e)
  }
}

export const getSimple = async (companyId) =>
  axiosInstance.get(`/simple?companyId=${companyId}`)
