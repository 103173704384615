import { useEffect, useState } from 'react'
import { ArchiveItem } from './ArchiveItem'
import BudgetPreview from '../../tasks/components/budgets/BudgetPreview'
import Dialog from '../../../components/modal/Dialog'
import { Close } from '@staccx/bento'
import { useSortList } from '../helpers/sortList'
import Select from 'react-select'
import { SortSelect } from './SortSelect'
import { AmountSelect } from './AmountSelect'
import { SearchDocuments } from './SearchDocuments'
import SignalSpinnerLoader from '../../../components/loaders/SignalSpinnerLoader'

export function ArchiveList({
  documents,
  amountToShow,
  setAmountToShow,
  isFetching,
}) {
  const [openDocument, setOpenDocument] = useState(false)
  const [activeDocument, setActiveDocument] = useState(null)
  const [sortOptions, setSortOptions] = useState({
    value: 'new',
    label: 'Nyeste',
  })
  const [search, setSearch] = useState('')
  function handleOpenDocument(document) {
    setOpenDocument(true)
    setActiveDocument(document)
  }

  const docs = useSortList(documents, sortOptions)
  if (documents.length === 0) {
    return (
      <div className="flex w-full h-[100vh] items-center justify-center">
        <span>Ingen arkiverte dokumenter</span>
      </div>
    )
  }
  return (
    <>
      <div className="lg:px-24 px-12 pt-44 w-full flex flex-col gap-8">
        <h1 className="text-xl">Signerte dokumenter</h1>
        <div className="flex lg:items-end flex-col lg:flex-row justify-between lg:gap-8 gap-4 ">
          <SearchDocuments setSearch={setSearch} />
          <div className="flex items-center gap-4">
            <SortSelect
              sortOptions={sortOptions}
              setSortOptions={setSortOptions}
            />
            <AmountSelect
              amountToShow={amountToShow}
              setAmountToShow={setAmountToShow}
            />
          </div>
        </div>
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-8 w-full relative">
          {isFetching ? (
            <div className="absolute h-[200px] w-full flex items-center justify-center">
              <SignalSpinnerLoader />
            </div>
          ) : (
            docs
              ?.filter((item) => {
                const filename = item?.filename?.toLowerCase() ?? ''
                const driver = item?.driver?.toLowerCase() ?? ''
                const brand = item?.carBrand?.toLowerCase() ?? ''
                const model = item?.carModel?.toLowerCase() ?? ''
                const searchableKeys = filename + driver + brand + model
                return searchableKeys?.includes(search.toLowerCase())
              })
              .map((doc) => (
                <ArchiveItem
                  key={doc.attachmentKey}
                  document={doc}
                  handleOpen={handleOpenDocument}
                />
              ))
          )}
        </div>
      </div>
      <Dialog
        width={'fit-content'}
        open={openDocument}
        onOpenChange={() => setOpenDocument(false)}
      >
        <button
          className="flex w-full p-2 text-blue-600 items-center gap-2 justify-end"
          onClick={() => setOpenDocument(false)}
        >
          Lukk <Close />
        </button>
        <BudgetPreview
          flowId={activeDocument?.flowId}
          attachmentKey={activeDocument?.attachmentId}
          mimeType={activeDocument?.mimetype}
          download={true}
        />
      </Dialog>
    </>
  )
}
