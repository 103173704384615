import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useCar } from '../../../context/CarProvider'
import Container from '../../../components/Container'
import styled from 'styled-components'
import CarCardNew from '../components/CarCardNew'
import Flex from '../../../components/Flex'
import { Car, CarStatus } from '../../../types'
import MileageCard from '../components/MileageCard'
import DriverInput from '../components/DriverInput'
import AgreementDetailsCard from '../components/AgreementDetailsCard'
import ServiceCard from '../components/ServiceCard'
import EventFilter from '../components/EventFilter'
import EventListTable from '../components/EventListTables'
import BannersAndToasts from '../components/BannersAndToasts'

const CarDetailsNew = () => {
  const { id } = useParams()
  const car: Car = useCar(id)

  const [currentFilter, currentFilterSet] = useState('ALL')
  const { accountNo } = car || {}

  if (!car) {
    return null
  }

  return (
    <Container isLoading={!car} renderChildrenDuringLoading={false}>
      <BannersAndToasts car={car} />
      <div className="flex flex-col gap-8 pt-12">
        <div className="flex lg:hidden pb-4">
          <DriverInput car={car} driver={car?.driver || null} />
        </div>
        <div className="flex flex-col lg:flex-row w-full gap-8 text-sm justify-between">
          <div className="flex flex-col gap-8 w-full">
            <CarCardNew car={car} />
            {car.status && car.status !== CarStatus.Incoming && (
              <MileageCard car={car} />
            )}
          </div>
          <div className="flex flex-col gap-8 w-full">
            <div className="hidden lg:flex">
              <DriverInput car={car} driver={car?.driver || null} />
            </div>
            <AgreementDetailsCard car={car} />
            {[CarStatus.Active, CarStatus.Outgoing].includes(car?.status) && (
              <ServiceCard car={car} />
            )}
          </div>
        </div>
        <div className="flex-col hidden md:flex w-full gap-8 pt-8">
          <EventFilter
            accountNo={accountNo}
            currentFilter={currentFilter}
            setFilter={currentFilterSet}
            showCarFilter={true}
          />
          <EventListTable
            carsData={[car]}
            currentFilter={currentFilter}
            isLoading={car === null}
          />
        </div>
      </div>
    </Container>
  )
}

export default CarDetailsNew

const TilesSection = styled.div`
  width: 100%;
`

const EventSection = styled.div`
  width: 100%;
`

const TilesSectionItem = styled.div`
  flex: 1;
`
