import { useUser } from '../../../context/UserProvider'
import { Archive } from './Archive'

export function ArchiveWrapper() {
  const user = useUser()
  if (user?.user.id) {
    return <Archive userId={user?.user.id} />
  }
  return null
}
