export function SearchDocuments({ setSearch }) {
  return (
    <div className="flex flex-col w-full gap-2 ">
      <label className="text-xs" htmlFor="search">
        Søk etter filnavn...
      </label>
      <input
        onChange={(e) => setSearch(e.target.value)}
        type="search"
        id="search"
        placeholder="Søk etter filnavn..."
        className="px-4 h-[40px] border-[1px] border-gray-300 rounded-md lg:w-2/3 w-full"
      ></input>
    </div>
  )
}
