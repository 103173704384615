import { FileIcon } from '@radix-ui/react-icons'
import { Divider } from '@staccx/bento'

export function ArchiveItem({ document, handleOpen }) {
  const signedDate = new Date(document?.completedAt).toLocaleString('no-NO')
  return (
    <div className="min-w-[200px] fade-in p-4 border-[1px] bg-white rounded-md border-gray-500 text-black shadow-md flex items-start justify-between w-full">
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-2 text-lg font-bold">
          <div className="transform scale-125">
            <FileIcon />
          </div>
          <span>{document?.filename}</span>
        </div>
        <Divider />
        {document?.carBrand && (
          <div className="flex items-center gap-2">
            <CarIcon />
            <span>
              {document.carBrand}
              {document?.carModel && `- ${document.carModel}`}
            </span>
          </div>
        )}
        {document?.driver && (
          <div className="flex items-center gap-2">
            <DriverIcon />
            <span>{document.driver}</span>
          </div>
        )}
        <span className="text-gray-400">Signert {signedDate}</span>
      </div>
      <button
        className="flex hover:underline cursor-pointer items-center justify-center gap-2 text-blue-500"
        onClick={() => handleOpen(document)}
      >
        <span className="hidden sm:inline">Åpne dokument</span>
        <span className="sm:hidden">Åpne</span>
        <OpenSvg />
      </button>
    </div>
  )
}

export function OpenSvg() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1545_9689)">
        <path
          d="M15.8536 0.146447C15.9015 0.194385 15.9377 0.249636 15.9621 0.308609C15.9862 0.366852 15.9988 0.428726 15.9999 0.490771L16 0.50098V7.5C16 7.77614 15.7761 8 15.5 8C15.2239 8 15 7.77614 15 7.5V1.70711L7.85355 8.85355C7.65829 9.04882 7.34171 9.04882 7.14645 8.85355C6.95118 8.65829 6.95118 8.34171 7.14645 8.14645L14.2929 1H8.5C8.22386 1 8 0.776142 8 0.5C8 0.223858 8.22386 0 8.5 0H15.4999C15.5677 0 15.6324 0.0134926 15.6914 0.0379391C15.7504 0.062339 15.8056 0.0985082 15.8536 0.146447Z"
          fill="currentColor"
        />
        <path
          d="M1.29289 4.29289C1.48043 4.10536 1.73478 4 2 4H6.5C6.77614 4 7 3.77614 7 3.5C7 3.22386 6.77614 3 6.5 3H2C1.46957 3 0.960859 3.21071 0.585786 3.58579C0.210714 3.96086 0 4.46957 0 5V14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H11C11.5304 16 12.0391 15.7893 12.4142 15.4142C12.7893 15.0391 13 14.5304 13 14V9.5C13 9.22386 12.7761 9 12.5 9C12.2239 9 12 9.22386 12 9.5V14C12 14.2652 11.8946 14.5196 11.7071 14.7071C11.5196 14.8946 11.2652 15 11 15H2C1.73478 15 1.48043 14.8946 1.29289 14.7071C1.10536 14.5196 1 14.2652 1 14V5C1 4.73478 1.10536 4.48043 1.29289 4.29289Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1545_9689">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export function DriverIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Driver</title>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 1C6.61929 1 5.5 2.11929 5.5 3.5C5.5 4.88071 6.61929 6 8 6C9.38071 6 10.5 4.88071 10.5 3.5C10.5 2.11929 9.38071 1 8 1ZM4.5 3.5C4.5 1.567 6.067 0 8 0C9.933 0 11.5 1.567 11.5 3.5C11.5 5.433 9.933 7 8 7C6.067 7 4.5 5.433 4.5 3.5Z"
        fill="#1E1E1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.05025 10.5503C4.36301 9.2375 6.14348 8.5 8 8.5C9.85652 8.5 11.637 9.2375 12.9497 10.5503C14.2625 11.863 15 13.6435 15 15.5C15 15.6326 14.9473 15.7598 14.8536 15.8536C14.7598 15.9473 14.6326 16 14.5 16H1.5C1.36739 16 1.24021 15.9473 1.14645 15.8536C1.05268 15.7598 1 15.6326 1 15.5C1 13.6435 1.7375 11.863 3.05025 10.5503ZM8 9.5C6.4087 9.5 4.88258 10.1321 3.75736 11.2574C2.7506 12.2641 2.13856 13.5918 2.02085 15H13.9791C13.8614 13.5918 13.2494 12.2641 12.2426 11.2574C11.1174 10.1321 9.5913 9.5 8 9.5Z"
        fill="#1E1E1E"
      />
    </svg>
  )
}

export function CarIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Car</title>
      <g clip-path="url(#clip0_1545_9780)">
        <path
          d="M4.00001 10C4.00001 10.5523 3.5523 11 3.00001 11C2.44773 11 2.00001 10.5523 2.00001 10C2.00001 9.44771 2.44773 9 3.00001 9C3.5523 9 4.00001 9.44771 4.00001 10Z"
          fill="#1E1E1E"
        />
        <path
          d="M13 11C13.5523 11 14 10.5523 14 10C14 9.44771 13.5523 9 13 9C12.4477 9 12 9.44771 12 10C12 10.5523 12.4477 11 13 11Z"
          fill="#1E1E1E"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.00639588 7.4199C0.001374 7.4506 -0.000696531 7.48131 1.3589e-05 7.51167V14.5C1.3589e-05 14.7761 0.223871 15 0.500014 15H4.50001C4.77616 15 5.00001 14.7761 5.00001 14.5V13H11V14.5C11 14.7761 11.2239 15 11.5 15H15.5C15.7762 15 16 14.7761 16 14.5V7.51168C16.0007 7.48131 15.9987 7.45059 15.9936 7.41987C15.9887 7.38916 15.9809 7.35938 15.9707 7.3308L14.2025 2.02623C13.9983 1.41219 13.4236 1 12.779 1H3.22101C2.57522 1 2.00174 1.41266 1.79756 2.02623L0.0293626 7.33081C0.0190882 7.35939 0.0113427 7.38918 0.00639588 7.4199ZM2.74647 2.34177C2.81438 2.13753 3.00493 2 3.22101 2H12.779C12.9944 2 13.1859 2.13815 13.2537 2.34211L14.8063 7H1.19373L2.74647 2.34177ZM12 13H15V14H12V13ZM15 12H1.00001V8H15V12ZM1.00001 13H4.00001V14H1.00001V13Z"
          fill="#1E1E1E"
        />
      </g>
      <defs>
        <clipPath id="clip0_1545_9780">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
