import React from 'react'
import styled from 'styled-components'
import Flex from '../../../components/Flex'
import { Car } from '../../../types'
import { formatDateString } from '../../../utils/dateHelper'
import { numberWithSpaceSeparator } from '../../../utils/formatNumber'
import { TranslatedText } from '@staccx/i18n'

const AgreementDetailsCard: React.FC<{ car: Car }> = ({ car }) => {
  return (
    <Wrapper>
      <Flex direction={'column'} gap={'var(--spacing-mediumMinus)'}>
        <HeadingText>
          <TranslatedText i18nKey={'AGREEMENT_DETAILS'} />
        </HeadingText>
        <div className="flex flex-col gap-4 md:flex-row">
          <Flex direction={'column'} gap={'var(--spacing-tinyMinus)'}>
            <div>
              <TranslatedText i18nKey={'INVOICE_GROUP'} />
            </div>
            <BoldText>{car.invoiceGroup || '-'}</BoldText>
          </Flex>
          <Flex direction={'column'} gap={'var(--spacing-tinyMinus)'}>
            <div>
              <TranslatedText i18nKey={'START_DATE'} />
            </div>
            <BoldText>
              {car.actualDeliveryDate
                ? formatDateString(new Date(car.actualDeliveryDate))
                : '-'}
            </BoldText>
          </Flex>
          <Flex direction={'column'} gap={'var(--spacing-tinyMinus)'}>
            <div>
              <TranslatedText i18nKey={'RETURN_DATE'} />
            </div>
            <BoldText>
              {car.plannedReturnDate
                ? formatDateString(new Date(car.plannedReturnDate))
                : '-'}
            </BoldText>
          </Flex>
        </div>
        {car.taxCalculationBasis && (
          <Flex direction={'column'} gap={'var(--spacing-tinyMinus)'}>
            <div>
              <TranslatedText i18nKey={'TAX_CALCULATION_BASIS'} />
            </div>
            <BoldText>{`${numberWithSpaceSeparator(
              car.taxCalculationBasis,
            )} kr`}</BoldText>
          </Flex>
        )}
      </Flex>
    </Wrapper>
  )
}

export default AgreementDetailsCard

const Wrapper = styled.div`
  background-color: var(--color-white);
  padding: var(--spacing-mediumPlus);
  width: 100%;
  font-size: var(--font-text);
`

const HeadingText = styled.div`
  font-size: var(--font-base);
  color: var(--color-primary);
`

const BoldText = styled.div`
  font-weight: var(--fontWeight-bold);
`
