import { useEffect, useState } from 'react'

export function useSortList(
  list: any[],
  sortSetting: { value: string; label: string },
): any[] {
  const [sortedList, setSortedList] = useState([...list])

  useEffect(() => {
    let newList = [...list]
    switch (sortSetting.value) {
      case 'new': {
        newList = newList.sort(
          (a, b) =>
            new Date(b.completedAt).getTime() -
            new Date(a.completedAt).getTime(),
        )
        break
      }
      case 'old': {
        newList = newList.sort(
          (a, b) =>
            new Date(a.completedAt).getTime() -
            new Date(b.completedAt).getTime(),
        )
        break
      }
      case 'A-Z': {
        newList = newList.sort((a, b) => a.filename.localeCompare(b.filename))
        break
      }
      case 'Z-A': {
        newList = newList.sort((a, b) => b.filename.localeCompare(a.filename))
        break
      }
      default:
        break
    }

    setSortedList(newList)
  }, [list, sortSetting])

  return sortedList
}
