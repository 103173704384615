import Select from 'react-select'

export function AmountSelect({ amountToShow, setAmountToShow }) {
  return (
    <div className="flex flex-col gap-2 ">
      <label className="text-xs" htmlFor="amount">
        Vis
      </label>
      <Select
        onChange={(e) => setAmountToShow(e)}
        className="w-40"
        id="amount"
        value={amountToShow}
        options={[
          {
            value: '10',
            label: '10',
          },
          {
            value: '25',
            label: '25',
          },
          {
            value: '50',
            label: '50',
          },
          {
            value: '9999',
            label: 'Alle',
          },
        ]}
      ></Select>
    </div>
  )
}
