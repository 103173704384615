import React from 'react'
import { Navigate } from 'react-router-dom'
import { useCarContext } from '../../../context/CarProvider'
import SignalSpinnerLoader from '../../../components/loaders/SignalSpinnerLoader'

const MyCar = () => {
  const carContext = useCarContext()
  const car = carContext.currentUsersCar

  if (!car) {
    return <SignalSpinnerLoader />
  }

  const id = car?.accountNo ?? car?.budgetNo
  return <Navigate to={`/cars/${id}`} replace />
}

export default MyCar
