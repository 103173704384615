import React from 'react'
import styled from 'styled-components'
import Flex from '../../../components/Flex'
import { Car } from '../../../types'
import { formatDateString } from '../../../utils/dateHelper'
import { addMonths } from 'date-fns'
import { TranslatedText } from '@staccx/i18n'
import { Close, Modal } from '@staccx/bento'
import { useQuery } from 'react-query'
import { serviceCardFromSanity } from '../../../api/api'
import BlockContent from '@sanity/block-content-to-react'
import blockSerializer from '../../../data/blockSerializers'
import Dialog from '../../../components/modal/Dialog'

const ServiceCard: React.FC<{ car: Car; regNr }> = ({ car }) => {
  const [showServiceCard, setShowServiceCard] = React.useState(false)
  const { data: serviceCard } = useQuery(['serviceCard'], () =>
    serviceCardFromSanity(),
  )
  return (
    <Wrapper>
      <Flex justify={'space-between'}>
        <Flex gap={'var(--spacing-mediumMinus)'}>
          <div className="flex flex-col gap-4">
            <button
              onClick={() => setShowServiceCard(true)}
              className="flex flex-col gap-2"
            >
              <span className="text-center w-full">Vis bakside</span>
              <ServiceCardWrapper>
                <img src="/service-card.svg" alt="service card image" />
              </ServiceCardWrapper>
            </button>
          </div>
          <div className="flex flex-col gap-4">
            <HeadingText>
              <TranslatedText i18nKey={'SERVICECARD_MENUITEM'} />
            </HeadingText>
            <div className="flex flex-col md:flex-row gap-4 text-sm">
              <Flex direction={'column'} gap={'var(--spacing-tinyMinus)'}>
                <div>
                  <TranslatedText i18nKey={'SERVICE_CARD_NO'} />
                </div>
                <BoldText>{car.budgetNo || 'Ukjent'}</BoldText>
              </Flex>
              <Flex direction={'column'} gap={'var(--spacing-tinyMinus)'}>
                <div>
                  <TranslatedText i18nKey={'EXPIRATION_DATE'} />
                </div>
                <BoldText>
                  {car.plannedReturnDate
                    ? formatDateString(
                        addMonths(new Date(car.plannedReturnDate), 1),
                      )
                    : 'Ukjent'}
                </BoldText>
              </Flex>
            </div>
          </div>
        </Flex>
      </Flex>
      {serviceCard && (
        <Dialog
          open={showServiceCard}
          onOpenChange={() => setShowServiceCard(false)}
        >
          <button
            className="flex w-full p-2 text-blue-600 items-center gap-2 justify-end"
            onClick={() => setShowServiceCard(false)}
          >
            Lukk <Close />
          </button>
          <div className="pb-4 flex flex-col gap-8">
            <h3 className="text-xl">
              {serviceCard?.[0]?.serviceCardheadingText?.nb}
            </h3>
            <div className="h-[1px] bg-slate-600 w-full" />
            <BlockContent
              serializers={blockSerializer}
              blocks={serviceCard?.[0]?.serviceCardBody?.nb}
            />
            <div className="h-[1px] bg-slate-600 w-full" />
            <div className="flex gap-8">
              <div className="flex flex-col">
                <span className=" text-gray-400">Reg.nr</span>
                <span>{car?.registrationNumber}</span>
              </div>
              <div className="flex flex-col">
                <span className=" text-gray-400">Kortnummer</span>
                <span>{car?.budgetNo}</span>
              </div>
              <div className="flex flex-col">
                <span className=" text-gray-400">Utløpsdato</span>
                <span>
                  {car.plannedReturnDate
                    ? formatDateString(
                        addMonths(new Date(car.plannedReturnDate), 1),
                      )
                    : 'Ukjent'}
                </span>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </Wrapper>
  )
}

export default ServiceCard

const Wrapper = styled.div`
  background-color: var(--color-white);
  padding: var(--spacing-smallPlus);
  font-size: var(--font-text);
  width: 100%;
`

const HeadingText = styled.div`
  font-size: var(--font-base);
  color: var(--color-primary);
`

const BoldText = styled.div`
  font-weight: var(--fontWeight-bold);
`

const ServiceCardWrapper = styled.div`
  width: 140px;
`
