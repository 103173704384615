import { getArchivedDocuments } from '../../../api/newApi'
import { useQuery } from 'react-query'
import { useUser } from '../../../context/UserProvider'
import { ArchiveList } from '../components/ArchiveList'
import React, { useEffect } from 'react'
import SignalSpinnerLoader from '../../../components/loaders/SignalSpinnerLoader'

export function Archive({ userId }) {
  const [amountToShow, setAmountToShow] = React.useState({
    label: '10',
    value: '10',
  })

  const {
    data: documents,
    isLoading,
    isError,
    isSuccess,
    isFetching,
    refetch,
  } = useQuery(
    ['documents', amountToShow.value],
    () => getArchivedDocuments(userId, amountToShow.value),
    {
      keepPreviousData: true,
    },
  )

  useEffect(() => {
    refetch()
  }, [amountToShow, refetch])

  if (isLoading) {
    return (
      <div className="min-h-[100vh] w-full flex-col flex items-center justify-center">
        <SignalSpinnerLoader />
        <span> Henter dokumenter...</span>
      </div>
    )
  }
  if (isError) {
    return <div>Noe gikk galt...</div>
  }
  if (isSuccess) {
    return (
      <div className="w-full flex flex-col gap-8">
        <ArchiveList
          isFetching={isFetching}
          documents={documents}
          amountToShow={amountToShow}
          setAmountToShow={setAmountToShow}
        />
        {!isFetching && (
          <div className="flex flex-col gap-2 lg:px-24 px-12 fade-in">
            <span>
              For øyeblikket er kun dokumenter signert i og etter Juni 2024
              tilgjengelig.
            </span>
            <span>
              For eldre dokumenter,{' '}
              <a
                href="/help"
                className="text-blue-500 hover:underline cursor-pointer"
              >
                vennligst kontakt Autoplan.
              </a>
            </span>
          </div>
        )}
      </div>
    )
  }
  return (
    <div className="min-h-[100vh] w-full flex items-center justify-center">
      Henter dokumenter...
    </div>
  )
}
