import React from 'react'
import { OpenId } from '@staccx/open-id'
import MyCar from './pages/cars/components/MyCar'
import Redir from './pages/redir/routes/Redir'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import Cars from './pages/cars/routes/Cars'
import Home from './pages/home/routes/Home'
import Invoices from './pages/invoices/routes/Invoices'
import Help from './pages/help/routes/Help'
import Information from './pages/information/routes/Information'
import Authorization from './pages/login/components/Authorization'
import Drivers from './pages/contacts/routes/Drivers'
import featureToggles from './autoplan_config'
import ChooseCarVariant from './pages/carConfiguration/routes/ChooseCarVariant'
import LandingPage from './pages/carConfiguration/routes/LandingPage'
import ConfigureBaseCar from './pages/carConfiguration/routes/ConfigureBaseCar'
import ConfigureCarOptions from './pages/carConfiguration/routes/ConfigureCarOptions'
import ConfigureWheelPackages from './pages/carConfiguration/routes/ConfigureWheelPackages'
import ConfigureAgreements from './pages/carConfiguration/routes/ConfigureAgreements'
import Summary from './pages/carConfiguration/routes/Summary'
import Confirmation from './pages/carConfiguration/routes/Confirmation'
import NoAccess from './pages/carConfiguration/routes/NoAccess'
import UnknownError from './pages/carConfiguration/routes/UnknownError'
import { useUserContext } from './context/UserProvider'
import {
  CHOOSE_CAR,
  CONFIGURE_AGREEMENTS,
  CONFIGURE_BASE_CAR,
  CONFIGURE_CAR_OPTIONS,
  CONFIGURE_WHEEL_PACKAGES,
  CONFIRMATION,
  LANDING_PAGE,
  NO_ACCESS,
  SUMMARY,
  UNKNOWN_ERROR
} from './pages/carConfiguration/hooks/useCarConfiguration'
import FleetReport from './pages/reports/routes/FleetReport'
import LeasingReport from './pages/reports/routes/LeasingReport'
import FleetReportDetails from './pages/reports/routes/FleetReportDetails'
import FleetReportExpense from './pages/reports/routes/FleetReportExpense'
import InvoiceReport from './pages/reports/routes/InvoiceReport'
import InvoiceReportDetails from './pages/reports/routes/InvoiceReportDetails'
import EnvironmentReport from './pages/reports/routes/EnvironmentReport'
import FuelReport from './pages/reports/routes/FuelReport'
import FuelReportDetails from './pages/reports/routes/FuelReportDetails'
import FuelReportExpenseDetails from './pages/reports/routes/FuelReportExpenseDetails'
import PurchaseReport from './pages/reports/routes/PurchaseReport'
import PurchaseReportDetails from './pages/reports/routes/PurchaseReportDetails'
import PurchaseReportAccountDetails from './pages/reports/routes/PurchaseReportAccountDetails'
import TollReport from './pages/reports/routes/TollReport'
import TollReportDetails from './pages/reports/routes/TollReportDetails'
import TollReportAccountDetails from './pages/reports/routes/TollReportAccountDetails'
import FleetExport from './pages/reports/routes/FleetExport'
import ReportsOverview from './pages/reports/routes/ReportOverview'
import PageLayout from './components/PageLayout'
import UserProfile from './pages/profile/routes/UserProfile'
import MileageReport from './pages/reports/routes/MileageReport'
import MileageReportDetails from './pages/reports/routes/MileageReportDetails'
import Tasks from './pages/tasks/routes/Tasks'
import BudgetSelection from './pages/tasks/routes/BudgetSelection'
import SigningComplete from './pages/tasks/routes/SigningComplete'
import SigningCallback from './pages/tasks/routes/SigningCallback'
import BudgetSelectionComplete from './pages/tasks/routes/BudgetSelectionComplete'
import CarDetailsNew from "./pages/cars/routes/CarDetailsNew";
import SigningBatchComplete from "./pages/tasks/routes/SigningBatchComplete";
import SigningBatchLoader from "./pages/tasks/routes/SigningBatchLoader";
import { ArchiveWrapper } from './pages/archive/routes/ArchiveWrapper'

const { customerCarConfiguration } = featureToggles

const AutoplanRoutes = () => {
  const { hasConfigurationAccess } = useUserContext()

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={'*'}
          element={
            <OpenId>
              {({ user }) => {
                if (!user) {
                  return <Authorization />
                }
                return (
                  <PageLayout>
                    <Outlet />
                  </PageLayout>
                )
              }}
            </OpenId>
          }
        >
          <Route path={'*'} element={<Redir />} />
          <Route path={'redir'} element={<Redir />} />
          <Route path={'archive'} element={<ArchiveWrapper />} />
          <Route path={'home'} element={<Home />} />
          <Route path={'cars'} element={<Cars />} />
          <Route path={'cars/:id'} element={<CarDetailsNew />} />
          <Route path={'drivers'} element={<Drivers />} />
          <Route path={'my-car'} element={<MyCar />} />
          <Route path={'reports'} element={<ReportsOverview />} />
          <Route path={'reports/fleet'} element={<FleetReport />} />
          <Route path={'reports/leasing'} element={<LeasingReport />} />
          <Route path={'reports/fleet/account/:account'} element={<FleetReportDetails />} />
          <Route path={'reports/fleet/expense/:account/:itemNo/:type'} element={<FleetReportExpense />} />
          <Route path={'reports/invoice'} element={<InvoiceReport />} />
          <Route path={'reports/invoice/:invoiceNo'} element={<InvoiceReportDetails />} />
          <Route path={'reports/environment'} element={<EnvironmentReport />} />
          <Route path={'reports/fuel'} element={<FuelReport />} />
          <Route path={'reports/fuel/:year/:month'} element={<FuelReportDetails />} />
          <Route path={'reports/fuel/expense/:account/:year/:month'} element={<FuelReportExpenseDetails />} />
          <Route path={'reports/purchase'} element={<PurchaseReport />} />
          <Route path={'reports/purchase/:year/:month'} element={<PurchaseReportDetails />} />
          <Route path={'reports/purchase/expense/:account/:year/:month'} element={<PurchaseReportAccountDetails />} />
          <Route path={'reports/toll'} element={<TollReport />} />
          <Route path={'reports/mileage'} element={<MileageReport />} />
          <Route path={'reports/mileage/account/:account'} element={<MileageReportDetails />} />
          <Route path={'reports/toll/:year/:month'} element={<TollReportDetails />} />
          <Route path={'reports/toll/transaction/:account/:year/:month'} element={<TollReportAccountDetails />} />
          <Route path={'reports/fleet-export'} element={<FleetExport />} />
          <Route path={'invoices/*'} element={<Invoices />} />
          <Route path={'document-archive'} element={<Information />} />
          <Route path={'help'} element={<Help />} />
          <Route path={'tasks'} element={<Tasks />} />
          <Route path={'tasks/budget-selection/:id'} element={<BudgetSelection />} />
          <Route path={'tasks/budget-selection/complete'} element={<BudgetSelectionComplete />} />
          <Route path={'tasks/signing/callback'} element={<SigningCallback />} />
          <Route path={'tasks/signing-batch/:flowId'} element={<SigningBatchLoader />} />
          <Route path={'tasks/signing/complete/:signingType?'} element={<SigningComplete />} />
          <Route path={'tasks/signing-batch/complete'} element={<SigningBatchComplete />}/>
          <Route path={'profile'} element={<UserProfile />} />
          {customerCarConfiguration && hasConfigurationAccess && (
            <>
              <Route key={LANDING_PAGE} path={'car-order'} element={<LandingPage />} />
              <Route key={CHOOSE_CAR} path={`car-order/:flowId/${CHOOSE_CAR}`} element={<ChooseCarVariant />} />
              <Route
                key={CONFIGURE_BASE_CAR}
                path={`car-order/:flowId/${CONFIGURE_BASE_CAR}`}
                element={<ConfigureBaseCar />}
              />
              <Route
                key={CONFIGURE_CAR_OPTIONS}
                path={`car-order/:flowId/${CONFIGURE_CAR_OPTIONS}`}
                element={<ConfigureCarOptions />}
              />
              <Route
                key={CONFIGURE_WHEEL_PACKAGES}
                path={`car-order/:flowId/${CONFIGURE_WHEEL_PACKAGES}`}
                element={<ConfigureWheelPackages />}
              />
              <Route
                key={CONFIGURE_AGREEMENTS}
                path={`car-order/:flowId/${CONFIGURE_AGREEMENTS}`}
                element={<ConfigureAgreements />}
              />
              <Route key={SUMMARY} path={`car-order/:flowId/${SUMMARY}`} element={<Summary />} />,
              <Route key={CONFIRMATION} path={`car-order/:flowId/${CONFIRMATION}`} element={<Confirmation />} />
              <Route path={'car-order/:flowId/error/'}>
                <Route key={NO_ACCESS} path={NO_ACCESS} element={<NoAccess />} />
                <Route key={UNKNOWN_ERROR} path={UNKNOWN_ERROR} element={<UnknownError />} />
              </Route>
            </>
          )}
          <Route path={'logout'} element={<OpenId>{({ logout }) => logout()}</OpenId>} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default AutoplanRoutes
