import "babel-polyfill"
import "./modernizr-grid"
import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import "./theme"
import "./index.css"
// import registerServiceWorker from "./registerServiceWorker"

ReactDOM.render(<App />, document.getElementById("root"))
// registerServiceWorker()
