import React from 'react'
import styled from 'styled-components'
import { downloadAttachment, getFlow } from '../../../../api/api'
import { useQuery } from 'react-query'
import CustomSkeleton from '../../../carConfiguration/components/skeleton/skeleton'

const BudgetPreview = ({ flowId, attachmentKey, mimeType, download }) => {
  const {
    data: file,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ['file', flowId, attachmentKey, mimeType],
    async () => downloadAttachment(flowId, attachmentKey, mimeType),
    {
      enabled: !!flowId && !!attachmentKey && !!mimeType,
    },
  )

  if (isError) {
    return (
      <div className=" flex items-center justify-center">
        <span className="p-4">Vi hadde problemer med å hente dokumentet</span>
      </div>
    )
  }
  return (
    <>
      <div className="w-[70svw]">
        {isLoading && (
          <CustomSkeleton
            skeletons={[
              {
                width: '100%',
                height: '70vh',
                count: 1,
              },
            ]}
            border={false}
          />
        )}
        {file && (
          <>
            <div className="flex sm:hidden h-[400px] items-center justify-center text-center">
              <span className="">
                Ved mindre skjermstørrelser har nettleseren problemer med å vise
                dokumentet korrekt. Vennligst{' '}
                <a
                  href={file}
                  download
                  className="text-blue-500 hover:underline cursor-pointer"
                >
                  last ned dokumentet istedenfor.
                </a>{' '}
              </span>
            </div>
            <iframe
              className="hidden sm:inline h-[70vh] w-full"
              title="file"
              src={`${file}#toolbar=0`}
            />
          </>
        )}
        {download && (
          <a
            href={file}
            download
            className="fixed bottom-10 hover:bg-blue-600 cursor-pointer shadow-lg right-10 rounded-md px-4 py-2 bg-blue-500 text-white"
          >
            Last ned dokument
          </a>
        )}
      </div>
    </>
  )
}

const FilePreviewContainer = styled.div`
  width: var(--size-large);
`

const FileIframe = styled.iframe`
  height: 70vh;
  width: 100%;
`
export default BudgetPreview
