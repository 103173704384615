import Select from 'react-select'

export function SortSelect({ sortOptions, setSortOptions }) {
  return (
    <div className="flex flex-col gap-2 ">
      <label className="text-xs" htmlFor="sort">
        Sorter
      </label>
      <Select
        id="sort"
        onChange={(e) => setSortOptions(e)}
        className="w-40"
        value={sortOptions}
        options={[
          {
            value: 'new',
            label: 'Nyeste',
          },
          {
            value: 'old',
            label: 'Eldste',
          },
          {
            value: 'A-Z',
            label: 'A - Z',
          },
          {
            value: 'Z-A',
            label: 'Z - A',
          },
        ]}
      ></Select>
    </div>
  )
}
