import { authService } from '../pages/login/routes/Login'
import axios from 'axios'
import { loggedoutReasons } from '../constants/statusMessages'

const shouldNavigateToLogout = (errorResponse: any) => {
  if (![401, 403].includes(errorResponse?.response?.status)) {
    return false
  }

  return errorResponse?.request?.responseURL.includes('/api/flows')
}

export const axiosInstance = axios.create({
  timeout: 300000,
  baseURL: '/api',
  headers: {
    'cache-control': 'no-cache',
  },
})

axiosInstance.interceptors.response.use(
  ({ data }) => data,
  (error) => {
    if (shouldNavigateToLogout(error)) {
      sessionStorage.setItem('loggedoutReason', loggedoutReasons.NORMAL_LOGOUT)
      window.location.replace('/logout')
    }
    return Promise.reject(error)
  },
)

axiosInstance.interceptors.request.use(async (config) => {
  const user = await authService.getUser()
  config.headers.authorization = `Bearer ${user.access_token}`
  return config
})

export const getArchivedDocuments = async (
  contactId: string,
  limit: string | number,
) => {
  try {
    const response = await axiosInstance.get(
      `/signed/documents?contactId=${contactId}&skip=0&limit=${limit}`,
    )
    return response
  } catch (error) {
    throw error.response.data.Message || error.message
  }
}

export const getAttachmentById = async (
  attachmentId: string,
  mimeType: string,
  flowId: string,
) => {
  return axiosInstance
    .get(`/attachments/${attachmentId}?flowId=${flowId}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': mimeType,
      },
    })
    .catch((error) => {
      throw error.response.data.Message || error.message
    })
}
